import { useRouter } from "next/router";
import React from "react";
import ContentPage from "../modules/content/components/ContentPage";

const HomePage = () => {
  const router = useRouter();

  return (
    <ContentPage path={(router.query.path as string)?.split("?")[0] ?? "/"} />
  );
};

export default HomePage;
